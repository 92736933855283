import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { addGoods, removeGoods } from '../../../features/Cart/cartSlice';
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Wrapper, ContentQuantity, Title, Quantity, Number, TitleChild, BtnUpDown, BtnDelete, Line, MLabel } from './styles';
import goodsApi from 'api/goodsApi';
import Common from 'Common';

const { showPrice } = Common.localStorageGetUserInfo();

const CartItem = ({ item }) => {
    const dispatch = useDispatch();

    const handleUpDown = async (num) => {
        try {
            const goods = item.goods;
            // Get Goods Price
            if (!goods.ColorNoID && !!showPrice) {
                const params = {
                    goodsID: goods.GoodsID,
                    goodsSubID: goods.GoodsSubID || 1,
                    goodsNum: item.quantity + num,
                }
                const { data } = await goodsApi.getGoodsPirce(params);
                if (goods.Price !== data.price) {
                    goods.Price = data?.price || "未定";
                }
            } else {
                goods.Price = "お問い合わせください。"
            }

            const selectedGoods = {
                goods: { ...goods },
                quantity: num
            }
            dispatch(addGoods(selectedGoods));
        } catch (error) {
            console.log(error);
        }

    }

    const handleRemoveGoods = () => {
        const selectedGoods = {
            goods: { ...item.goods },
            quantity: item.quantity
        }
        dispatch(removeGoods(selectedGoods));
    }

    return (
        <Wrapper>
            <Title>
                {`${item.goods.GoodsName} ${item.goods.ColorNoName || ''}`}
                <TitleChild>　¦ {item.goods.GoodsSize}</TitleChild>
            </Title>
            {!!showPrice && (<Line><MLabel>価格：</MLabel>{item.goods.Price}</Line>)}
            <ContentQuantity>
                <Quantity>
                    <BtnUpDown onClick={() => { if (item.quantity > 1) handleUpDown(-1); }} ><FontAwesomeIcon icon={faMinus} /></BtnUpDown>
                    <Number>{item.quantity}</Number>
                    <BtnUpDown onClick={() => { handleUpDown(+1); }}><FontAwesomeIcon icon={faPlus} /></BtnUpDown>
                </Quantity>
                <BtnDelete onClick={() => { handleRemoveGoods() }}>削除</BtnDelete>
            </ContentQuantity>
        </Wrapper>
    )
}

CartItem.propTypes = {
    item: PropTypes.object.isRequired
}

export default CartItem